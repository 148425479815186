import React from "react";
import { Box, Link, Typography, List, ListItem } from "@mui/material";
import Layout from "../components/Layout";
import { PageProps } from "gatsby";
import { SEO } from "../components/SEO";

const listTitle = {
  my: 0.8,
};

const Communication = ({ location }: PageProps) => {
  return (
    <Layout>
      <SEO title="Communication" pathname={location.pathname} />
      <Box
        component="div"
        sx={{
          maxWidth: {
            xs: "100%",
            md: "calc(100% - 50px)",
            xl: "calc(100% - 100px)",
          },
          mx: "auto",
          mt: 14,
          mb: 2,
          backgroundColor: "#091A3D",
          border: "1px solid #4B608D",
          py: { xs: 3, md: 4 },
          px: { xs: 3, md: 8 },

        }}
      >
        <Box component="section">
          <Box component="p">
            <Typography sx={{}}>
              Beware of scams impersonating Jump. We do not have retail customers or otherwise manage
              investments for third parties. Anyone using our name to solicit or accept investments
              from you is trying to defraud you. Unfortunately, Jump Trading Group is aware of
              sophisticated scammers attempting to carry out this activity, using tactics including
              the creation of profiles on chat and social media applications and the use of fake
              websites and mobile applications.
            </Typography>
          </Box>
          <Box component="p">
            Under no circumstances should any member of the public transfer cryptocurrency or any
            funds to any wallet address on the basis of a purported link to a Jump Trading Group
            entity.
          </Box>
          <Box component="p">
            If you would like to share information regarding fraudulent activity related to Jump
            Trading Group, please reach out to&nbsp;
            <Link href="mailto:BrandProtection@jumptrading.com">
              BrandProtection@jumptrading.com
            </Link>
            . If you believe you are a victim of fraud, please contact local law enforcement.
          </Box>

          <Box component="p">
            Below are the official websites and social media accounts operated by Jump Trading
            Group.
          </Box>

          <Typography sx={listTitle} variant="h6">Websites:</Typography>
          <Box component="div">
            <List dense>
              <ListItem>https://www.jumptrading.com/</ListItem>
              <ListItem>https://jumpcrypto.com/</ListItem>
              <ListItem>https://jumpcap.com/</ListItem>
              <ListItem>https://www.jumpliquidity.com/</ListItem>
            </List>
          </Box>

          <Typography sx={listTitle} variant="h6">LinkedIn:</Typography>
          <Box component="div">
            <List dense>
              <ListItem>https://www.linkedin.com/company/jump-trading</ListItem>
              <ListItem>https://www.linkedin.com/company/jump-crypto</ListItem>
              <ListItem>https://www.linkedin.com/company/jump-capital</ListItem>
            </List>
          </Box>

          <Typography sx={listTitle} variant="h6">Twitter/X:</Typography>
          <Box component="div">
            <List dense>
              <ListItem>@jumptrading</ListItem>
              <ListItem>@jump_</ListItem>
              <ListItem>@JumpEngineering</ListItem>
              <ListItem>@jumpcapital</ListItem>
            </List>
          </Box>

          <Box component="p">
            Jump Trading Group does not have any mobile applications, or official channels on apps including Telegram or Whatsapp.</Box>
        </Box>

      </Box>
    </Layout>
  );
};

export default Communication;
